
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import moment from 'moment';

import axios from 'axios';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'finman-invoice',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      load: true,
      invoice:{} as any,
      invoiceDetails: [] as any,
      grand_total: 0,
      invoiceDetailsData: [] as any,
      vat_total: 0 as any,
      value_total: 0 as any,
      loading: false,
      tableData: [] as any,
      componentKey: 0,
      moment: "" as any,
    };
  },

  async created() {
    await this.setInvoiceData();
  },
  methods: {
    async setInvoiceData()
    {
      let invoice_no=  this.$route.query.invoice_no;
      ApiService.get("finmanInvoice/invoice/getInvoiceDetailsView/"+ `${invoice_no}`)
      .then((response) => {
        this.invoice= response.data.data.invoice;
        this.invoiceDetails= response.data.data.invoice_details;
        this.invoice.date = moment(this.invoice.date).format('DD-MM-YYYY');
        this.calculateTotal();
      }).catch(({ response }) => {
            console.log(response);
      });
      
      // this.product.invoice_number=invoiceData.invoice.invoice_no;
    },


    calculateTotal()
    {
      const sum = this.invoiceDetails.reduce(
            (acc, cur) => {
              acc.vat_total += cur.vat_amount;
              acc.grand_total += cur.net_total;
              acc.value_total += (cur.quantity* cur.unit_price);
              return acc;
            },
            {
              vat_total: 0,
              grand_total: 0,
              value_total: 0,
            }
          );
      this.grand_total= sum.grand_total;
      this.vat_total= sum.vat_total;
      this.value_total= sum.value_total;
    },

    editInvoice(invoice_id) {
      this.emitter.emit('sales-edit-type', invoice_id);
      this.$router.push(
        {
          path: 'edit-invoice',
          query: 
          {
            id: invoice_id,
          }
        });
    },
    

    Deleteinvoice(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('finmanInvoice/invoice/deleteSale/' + `${id}`)
            .then((response) => {
              this.emitter.emit('invoice-added', true);
              Swal.fire('Deleted!', response.data.data, 'success');
              this.$router.push(
              {
                path: 'sales-invoices',
              });
            })
            .catch(({ response }) => {
              console.log(response);
              location.reload();
            });
        }
      });
    },
  },
});
